<template>
    <div v-if="isActive" class="carousel-wrapper">
        <div class="main-carousel">
            <VueSlickCarousel v-if="isMobile" ref="c1" v-bind="getMainSettings"
                              @init="onInitMainCarousel">
                <div class="image-item" v-for="(slide, k) in getSlides" :key="k"><img class="image-object" :src="slide.src" :alt="slide.alt"/></div>
            </VueSlickCarousel>
            <div v-else>
                <div v-for="(slide, k) in getSlides" :key="k"><img :id="`main-carousel-${k}`" class="img" :src="slide.src"
                                                                   :alt="slide.alt"/></div>
            </div>
        </div>
        <div class="thumbs">
            <div class="arrow" @click="showPrev" aria-label="Previous">&#8963;</div>
            <VueSlickCarousel class="carousel-cmpt" ref="c2" v-bind="getThumbnailSettings" @init="onInitThumbCarousel">
                <div v-for="(slide, k) in getSlides" :key="k" @click="scroll(k)"><img :src="slide.thumb"
                                                                                      :alt="slide.alt"/></div>
            </VueSlickCarousel>
            <div class="arrow arrow-next" @click="showNext" aria-label="Next">&#8963;</div>
        </div>
    </div>
</template>

<script>
  import VueSlickCarousel from 'vue-slick-carousel'
  import sampleData from '../image-sample-data'
  // optional style for arrows & dots

  export default {
    name: "ImageGallery",
    components: {VueSlickCarousel},
    data() {
      return {
        selectedVariant: null,
        slides: {},
        c1: undefined,
        c2: undefined
      }
    },
    props: {
      dev: {
        default: false,
        required: false
      }
    },
    mounted() {
      this.c1 = this.$refs.c1;
      this.c2 = this.$refs.c2;
      // get product, separate into variants, on variant change load different slides

      document.addEventListener('shopify/variant-select', (e) => {
        if (e.detail.variantId) {
          this.selectedVariant = parseInt(e.detail.variantId, 10);
        }

        this.resetCarouselPosition()
      });

      document.addEventListener('shopify/image-gallery-reset', () => {
        this.selectedVariant = null;
        this.resetCarouselPosition()
      });

      const queryString = window.location.search;
      const parameters = new URLSearchParams(queryString);
      const value = parameters.get('variant');
      if (value) {
        this.selectedVariant = parseInt(value, 10)
      }

      // window.addEventListener('resize', () => {
      //   this.c1.setPosition()
      // });
      // window.addEventListener('orientationchange', () => {
      //   this.c2.setPosition()
      // });


    },
    methods: {
      onInitThumbCarousel() {

      },
      onInitMainCarousel() {

      },
      scroll(k) {
        let x = document.querySelector(`#main-carousel-${k}`);
        if (x) {
          x.scrollIntoView()
        }
      },
      showNext() {
        this.$refs.c2.next()
      },
      showPrev() {
        this.$refs.c2.prev()
      },
      resetCarouselPosition () {
        window.setTimeout(() => {
          this.c1.goTo(0);
          this.c2.goTo(0);
        }, 100);
      }
    },
    computed: {
      isMobile() {
        return true
      },
      isActive() {
        return this.getSlides.length > 0
      },
      getSlides() {
        let pageData = {}
        if(!this.dev) {
          pageData = window.ShopifyPageData || {};
        } else {
          pageData = sampleData
        }


        if (Object.keys(pageData).length === 0) {
          return []
        }

        if (this.selectedVariant) {
          let variant = (((pageData || {}).product || {}).variants || []).find(x => x.id === this.selectedVariant);
          if (variant) {
            let res = pageData.variantMedia.filter(x => {
              let altSet = (x.alt || '').split('|').map((y) => {
                return y.toUpperCase().trim()
              })

              return altSet.includes((variant.title || '').toUpperCase().trim()) || (x.alt || '').toUpperCase().trim() === 'ALL'
            })

            if(res.length) {
              return res;
            }
          }
        }

        let variantTitles = (((pageData || {}).product || {}).variants || []).map(x => (x.title || '').toUpperCase().trim());
        let res = pageData.variantMedia.filter(x => !variantTitles.includes((x.alt || '').toUpperCase().trim()));

        if(res.length) {
          return res;
        }

        return pageData.variantMedia;
      },
      getThumbnailSettings() {
        return {
          asNavFor: this.c1,
          "focusOnSelect": true,
          "infinite": true,
          "slidesToShow": 5,
          "slidesToScroll": 1,
          "speed": 500,
          "vertical": true,
          "verticalSwiping": true,
          "dots": false,
          "swipeToSlide": true,
          arrows: false,
          //"lazyLoad": "ondemand",
        }
      },
      getMainSettings() {
        return {
          asNavFor: this.c2,
          "focusOnSelect": true,
          "infinite": true,
          "slidesToShow": 1,
          "slidesToScroll": 1,
          "speed": 500,
          "dots": false,
          "adaptiveHeight": true,
          "lazyLoad": "ondemand",

          swipe: true,
          "swipeToSlide": true,
          touchMove: true,
          draggable: true,
          arrows: false,
        }
      }
    }
  }
</script>

<style scoped lang="scss">
    .carousel-wrapper {
        position: relative;
        .main-carousel {
            min-height: 60vh;

            .image-item {
                isolation: isolate;
                background: #f5f5f5;
                position: relative;

                .image-object {
                    width: 100%;
                    backface-visibility: hidden;
                    mix-blend-mode: multiply;
                }
            }
        }

        .thumbs {
            left: 0;
            max-width: 58px;
            position: absolute;
            top: -86%;
            height: 86%;
            font-size: 0;
            width: 15%;
            margin-top: 1rem;

            &:before {
                content: "";
                display: inline-block;
                height: 100%;
                vertical-align: middle;
            }

            .carousel-cmpt {
                height: 86%;
            }

            img {
                width: 100%;
            }

            .arrow {
                text-align: center;
                cursor: pointer;
                font-size: 1.5rem;
                padding: 0.1rem 0;
                font-weight: bold;
                -webkit-touch-callout: none; /* iOS Safari */
                -webkit-user-select: none; /* Safari */
                -khtml-user-select: none; /* Konqueror HTML */
                -moz-user-select: none; /* Old versions of Firefox */
                -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
            }

            .arrow-next {
                transform: rotate(180deg);
            }
        }
    }
/*
    .carousel-wrapper {
        text-align: left;
        position: relative;
    }

    .thumbs {
        height: 100%;
        left: 0;
        max-width: 58px;
        position: absolute;
        top: 0;
        font-size: 0;
        width: 15%;

        @media (min-width: 1024px) {
            top: 232px;
            height: 50vh;
            position: fixed;
        }

        img {
            width: 75px;
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            user-drag: none;

            @media (min-width: 1024px) {
                width: 100px;
            }
        }
    }

    .main-carousel {
        .image-slide {
            isolation: isolate;
        }

        img {
            height: 50vh;
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            user-drag: none;
            margin: 0 auto 0 75px;

            backface-visibility: hidden;
            mix-blend-mode: multiply;

            @media (min-width: 1024px) {
                margin: 0 auto 0 100px;
            }
        }
    }
*/

</style>
