<template>
    <div v-if="loaded" class="radio__swatches">
        <div class="radios__swatches-wrapper">
            <div class="radios__swatches-item" v-for="variant in product.variants" :key="variant.id">
                <input :id="`SingleOptionSelector-${variant.id}--radio`"
                       class="radios__swatches-input"
                       @change="onChange"
                       @mouseover="hoverTitle = variant.option1"
                       @mouseleave="hoverTitle = false"
                       type="radio"
                       name="swatch-grid"
                       :value="variant.id"
                       :checked="variant.id === activeVariant.id"
                       :style="getStyle(variant)"/>
                <label class="radios__swatches-label visually-hidden" :for="`SingleOptionSelector-${variant.id}--radio`">
                    {{variant.option1 }}
                </label>
            </div>
        </div>
        <div v-if="activeVariant && activeVariant.options" class="radios__label">
            <div v-for="(option, i) in product.options" :key="option">
                {{ option }}: <span v-if="!hoverTitle" class="product__label-active">{{ activeVariant.options[i] }}</span>
                <span v-else>{{ hoverTitle }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import sampleData from '../image-sample-data'
  export default {
    name: "Swatches",
    data() {
      return {
        loaded: false,
        pageType: null,
        activeVariant: {},
        product: {},
        id: this.$parent.id,
        available: true,
        hoverTitle: false
      }
    },
    props: {
      dev: {
        required: false,
        default: false
      }
    },
    mounted() {
      let pageData;
      if(this.dev) {
        pageData = sampleData
      } else {
        pageData = window.ShopifyPageData
      }

      if (pageData.pageType === 'product') {
        this.product = pageData.product;

        // set active variant
        const parameters = new URLSearchParams(window.location.search);
        const value = parameters.get('variant');
        if (value) {
          this.onChange(value)
        } else if (this.product.variants) {
          this.onChange(this.product.variants[0].id)
        }
      } else {
        this.product = pageData.products[this.id]
      }

      this.pageType = pageData.pageType;
      this.loaded = true;
    },
    methods: {
      onChange(e) {
        let variant = typeof e === 'object' ? e.target.value : e;
        // set active variant in history
        this.activeVariant = this.product.variants.find(x => x.id.toString() === variant.toString());

        if (!this.activeVariant) {
          console.error('Unable to set variant');
          return;
        }

        if (this.pageType === 'product' && window.history.pushState) {
          const url = window.location.protocol + '//' + window.location.host + window.location.pathname + `?variant=${this.activeVariant.id}`
          window.history.replaceState({path: url}, '', url)
        }

        // dispatch active variant change (for image change + price update + set buy button data)
        document.dispatchEvent(
          new CustomEvent('shopify/variant-select', {
            'detail': {
              productId: this.product.id,
              variantId: this.activeVariant.id,
              variantSku: this.activeVariant.sku,
              variantAlt: this.activeVariant.title,

            }
          })
        );

        if (window.Shopify && window.ShopifyPageData.pageType === 'product') {
          let form = document.querySelector('form[action="/cart/add"]')
          let select = form.querySelector('select[name="id"]')
          if (select) {
            select.value = this.activeVariant.id
          }
        }
      },
      slugify(text) {
        return text.toString().toLowerCase().trim()
          .replace(/\s+/g, '-')           // Replace spaces with -
          .replace(/&/g, '-and-')         // Replace & with 'and'
          .replace(/[^\w-]+/g, '-')       // Remove all non-word chars
          .replace(/--+/g, '-');        // Replace multiple - with single -
      },
      getStyle(variant) {
        //todo load via liquid
        return {
          "--fill": this.slugify(variant.swatch_color || ''),
          "--image": "url(" + variant.swatch_image + ")"
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
    :root {

    }
    $size: 30px;

    .radio__swatches {
        text-align: left;
        padding: 1rem 0.2rem;
    }
    .radios__swatches-wrapper {
        display: flex;
        align-items: center;
        justify-items: left;
        flex-wrap: wrap;
    }

    .radios__swatches-item {
        display: flex;
        margin-right: 0.6rem;
        margin-bottom: 1rem;
    }
    label {
        visibility: hidden;
        font-size: 0;
    }

    input[type='radio'] {
        margin: 0;
        padding: 0;
        appearance: none;
        position: relative;
        height: $size;
        width: $size;
        left: auto;
        opacity: 1;
        visibility: visible;
        border: none;
        cursor: pointer;
    }
    /*gives the radio button white outer ring*/
    input[type='radio']:after {
        width: $size;
        height: $size;
        border-radius: $size + 2;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: var(--fill);
        background-image: var(--image);
        content: '';
        display: inline-block;
        visibility: visible;
        border: 1px solid #dfdfdf;
    }

    /*gives radio button orange fill when clicked*/
    input[type='radio']:checked:after {
        width: $size;
        height: $size;
        border-radius: $size + 2;
        top: -2px;
        left: -1px;
        position: relative;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid black;
    }

</style>
