<template>
  <div>
  <div id="dummyBlur" @click.prevent="handleBgClick"></div>
  <div class="container">
    <div class="vtoContainer">
      <div class="loading">
        <span class="text">Loading Try-On...</span>
      </div>
      <MVtoTile />
      <div v-if="selectedSwatch.title" class="vtoMobileTitle">
        <span>{{selectedSwatch.title}}</span>
      </div>
    </div>
    <div class="shadeSelector" v-if="isDesktop">
      <h2>IGK's LIVE COLOR TRY-ON —</h2>
      <h3>{{ type !== 'mask' ? "What’s your natural hair color?" : "This is next-gen hair color." }}</h3>
      <div class="shadeTabContainer">
        <button @click.prevent="scrollTabs(-1)">⬅</button>
        <div id="shadeTabs">
          <button
              v-for="(fam, idx) in Object.keys(shadeGroupings)"
              class="groupButton"
              :class="isButtonSelected(fam)"
              :key="idx"
              @click.prevent="handleShadeFamilySwap(fam)"
          >
            {{fam}}
          </button>
        </div>
        <button @click.prevent="scrollTabs(1)">⮕</button>
      </div>
      <h4 class="shadeTitle">
        {{selectedSwatch.title}}
        <span v-if="Object.keys(selectedSwatch).length">-</span>
        {{selectedSwatch.subtitle}}
      </h4>
      <div id="shadeSwatches">
        <img
            class="swatch"
            :class="{selected: swatch.colorCode === selectedSwatch.colorCode}"
            v-for="swatch in groupedSwatches"
            :src="swatch.src || '#'"
            :alt="swatch.title"
            :key="swatch.colorCode"
            :style="{'background-color': '#' + (swatch.hex_color).replace('#','')}"
            @click="handleSwatchClick(swatch)"
        />
      </div>
      <span class="errorMsg" v-if="needToSelect">Select a shade to buy!</span>
    </div>
    <div class="shadeSelectorMobile" v-else>
      <div id="shadeSwatchesMobile">
        <img
            class="swatch"
            v-for="swatch in allSwatches"
            :src="swatch.src"
            :key="swatch.colorCode"
            :alt="swatch.title"
            @click="handleSwatchClick(swatch)"
        />
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import MVtoTile from '@/components/molecules/m-vto-tile.vue'
import productMap from '@/product-map.json'
import productMaskMap from '@/../src/product-mask-map.json';

export default {
  name: "OVtoPopup",
  components: {MVtoTile},
  props: {
    verifiedEmail: {
      required: false
    },
    initialShadeFamily: {
      required: false,
    },
    initialSwatch: {
      required: false
    }
  },
  emits: ["close", "openNftUnlock", "clickBuyBtn"],
  data: function() {
    return {
      type: window.vtoType,
      shadeGroupings: window.vtoType === 'mask' ? {
        "Color Depositing Mask": [
          'K1DMBRB01A1', 'K1DMSBM01A1', 'K1DMMGS01A1',
          'K1DMCAL01A1', 'K1DMSFM01A1', 'K1DMFOT01A1', 'K1DMPAB01A1',
          'K1DMP2K01A1', 'K1DMPBL01A1', 'K1DMHPL01A1', 'K1DMTAR01A1',
          'K1DMCYT01A1', 'K1DMELB01A1', 'K1DMWBU01A1', 'K1DMOTM01A1',
          'K1DMALP01A1', 'K1DMFPY01A1', 'K1DMDCB01A1'
        ]
      } : {
        "Black": ['1B', '2N', 'K1CKEVO01A1'],
        "Dark Brown": ['1B', '2N', '4N', '5N', '5GA', 'K1CKEBB01A1', 'K1CKEFM01A1'],
        "Light Brown": ['1B', '2N', '4N', '5N', '5GA', '5VM', '5G', '5GM', '6N', '6AG', '6R', '6C', '6G', '7N', '7VG', '7G'],
        "Dark Blonde": ['4N', '5N', '5GA', '5VM', '5G', '5GM', '6N', '6AG', '6R', '6C', '6G', '7N', '7VG', '7G', '7C', '8N', '8BG', 'K1CKEBM01A1'],
        "Blonde": ['5N', '5GA', '6N', '6R', '6C', '6G', '7N', '7VG', '7G', '7C', '8N', '8BG', '9N', '9NA', '9GB'],
        "Light Blonde": ['5N', '6R', '6C', '7N', '7VG', '7G', '7C', '8N', '8BG', '9N', '9NA', '9GB', 'RG', 'K1CKEMP01A1', 'K1CKESK01A1', 'K1CKETD01A1'],
        "Auburn": ['1B', '2N', '4N', '5N', '5GA', '5VM', '5G', '5GM', '6N', '6R', '6C', '6G', '7C', 'K1CKEOH01A1'],
        "White": ['7VG', '7G', '8N', '8BG', '9N', '9GB', 'V', 'RG']
      },
      closedEvt: null,
      selectedShadeFamily: window.vtoType === 'mask' ? 'Color Depositing Mask' : 'Light Brown',
      selectedSwatch: {},
      isDesktop: true,
      needToSelect: false
    }
  },
  beforeMount: function() {
    this.isDesktop = window.innerWidth >= 1024
  },
  mounted: function() {
    window.YMKRunner = window.YMKRunner || [];
    window.YMKRunner.push(() => {
      this.closedEvt = window.YMK.addEventListener('closed', () => {
        this.$emit('close')
      })
    })
    let tabsEl = document.getElementById("shadeTabs")
    if (tabsEl) tabsEl.scrollLeft += 20;

    if(this.initialShadeFamily && this.initialSwatch) {
      this.selectedShadeFamily = this.initialShadeFamily;
      this.selectedSwatch = this.initialSwatch
    }
  },
  beforeUnMount: function() {
    if (this.closedEvt && window.YMK) {
      window.YMK.removeEventListener(this.closedEvt)
    }
  },
  computed: {
    allSwatches: function() {
      let map = window.vtoType === 'mask' ? productMaskMap : productMap
      return Object.keys(map).map(key => {
        return {
          colorCode: key,
          src: require(`@/assets/swatches/${key}.jpg`),
          ...map[key]
        }
      })
    },
    groupedSwatches: function() {
      return this.allSwatches.filter(swatch => {
        return (this.shadeGroupings[this.selectedShadeFamily] || []).includes(swatch.colorCode)
        //return swatch.shade_family === this.selectedShadeFamily
      })
    }
  },
  methods: {
    handleBuyClick: function() {
      this.needToSelect = false
      if (!this.verifiedEmail) {
        this.$emit('openNftUnlock')
      } else {
        if (!this.selectedSwatch || !this.selectedSwatch.colorCode) {
          this.needToSelect = true
        } else {
          this.$emit('clickBuyBtn', this.selectedSwatch.colorCode)
        }
      }
    },
    scrollTabs: function(dir) {
      let el = document.getElementById('shadeTabs')
      el.scrollLeft += (el.offsetWidth * dir)
    },
    handleShadeFamilySwap: function(family) {
      this.needToSelect = false
      this.selectedShadeFamily = family;
      this.selectedSwatch = {}
    },
    handleSwatchClick: function(swatch) {
      this.needToSelect = false
      this.selectedSwatch = swatch
      if (window.YMK && typeof window.YMK.applyMakeupBySku === 'function') {
        window.YMK.applyMakeupBySku(swatch.sku)
      }
    },
    handleBgClick: function() {
      this.needToSelect = false
      this.$emit('close')
    },
    isButtonSelected: function(fam) {
      if (fam === this.selectedShadeFamily) {
        return 'selected'
      } else return ''
    }
  }
}
</script>

<style scoped>
  button {
    border-radius: 0;
    display: inline-block;
    cursor: pointer;
    padding: 0;
    border: none;
    background: 0 0;
  }

.errorMsg {
  color: darkred;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 12px;
  margin: -21px auto 3px;
}
#unlockTitle {
  margin-top: auto;
}
.nftUnlockBtn {
  cursor: pointer;
  transform: scale(0.83);
  margin: -24px auto 0 auto;
}
.shadeTitle {
  padding: 11px 0 13px;
  min-height: 45px;
  max-height: 45px;
  display: flex;
  margin: 0;
  text-transform: uppercase;
}
.shadeTitle > span {
  margin: 0 5px;
}
#shadeSwatches {
  display: flex;
  flex-wrap: wrap;
  min-height: 240px;
  max-height: 240px;
  align-content: flex-start;
}
#shadeSwatchesMobile {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  background-color: #F1F1F1;
  padding: 12px 12px;
  width: 100vw;
}
.swatch {
  cursor: pointer;
  border-radius: 100px;
  box-sizing: border-box;
  display: inline-block;
  width: 35px;
  height: 35px;
  object-fit: contain;
  margin: 2px 2px 7px;
}

  .swatch.selected {
    border: 2px solid black;
  }
/*
.swatch:first-child, .swatch:nth-child(6n) {
  margin-left: 8px;
  margin-right: 6px;
}
.swatch:nth-child(5n) {
  margin-left: 6px;
  margin-right: 8px;
}

 */
.shadeTabContainer {
  display: flex;
  flex-wrap: nowrap;
}
#shadeTabs {
  display: flex;
  justify-content: space-between;
  min-height: 35px;
  margin: 2px 9px 0;
  overflow-x: scroll;
  scrollbar-width: none;
  scroll-behavior: smooth;
  scroll-snap-type: x proximity;
  border-right: 1px solid black;
  border-left: 1px solid black;
}
#shadeTabs::-webkit-scrollbar {
  /* Safari & Chrome hide scrollbar */
  display: none;
}
.groupButton {
  font-weight: 400;
  text-transform: uppercase;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-top: 1px solid black;
  padding: 10px 10px;
  white-space: nowrap;
  scroll-snap-type: x proximity;
}
  .groupButton.selected {
  border-bottom: 5px solid black;
  background-image: url(https://igkhair.s3.amazonaws.com/public/Holo_CTA.png);
  background-size: cover;
}
.container {
  box-sizing: content-box;
  z-index: 999;
  position: fixed;
  border: 2px solid black;
  /* VTO Tile is fixed at 360x480 */
  min-width: 720px;
  min-height: 480px;
  left: calc(100vw/2 - 720px/2);
  top: calc(100vh/2 - 480px/2);
  display: flex;
  width: auto;
}
@media screen and (max-width: 1023px) {
  .container {
    border: none;
    width: 100vw;
    height: fit-content;
    /*top: 2.5%;*/
    left: 0;
    display: flex;
    flex-direction: column;
    top: 2rem;
  }
}
.vtoContainer {
  min-width: 360px;
  background-color: #dbdbdb;
  position: relative;
}

.vtoContainer .vtoMobileTitle {
  display: none;
}

@media screen and (max-width: 1023px) {
  .vtoContainer {
    width: 100%;
    min-height: 412px;
    position: relative;
  }

  .vtoContainer .vtoMobileTitle {
    display: block;
    position: absolute;
    z-index: 999;
    font-weight: bold;
    top: 0;
    width: 100vw;
    text-align: center;
    margin-top: 5px;
  }

  .vtoContainer .vtoMobileTitle span {
    background: rgba(255,255,255,0.5);
    padding: 2px 5px;
  }
}
.shadeSelector {
  width: 360px;
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem;
  z-index: 1;
}
.shadeSelector > h2 {
  font-size: 1.5em;
  font-weight: 700;
  margin-top: 10px;
  text-transform: uppercase;
}
.shadeSelector > h3 {
  font-size: 1.5em;
  font-weight: bold;
  margin-top: 10px;
  font-family: "Canela", cursive;
  font-style: italic;
  margin-bottom: 1rem;
  text-transform: capitalize;
}
#dummyBlur {
  /* empty element to blur the whole background */
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 998;
  backdrop-filter: blur(5px);
  top: 0;
  left: 0;
}
.loading {
  position: fixed;
  top: 48%;
  left: 32%;
}
@media screen and (max-width: 1023px) {
  .loading {
    top: 33%;
    left: 34%;
  }
}
</style>
