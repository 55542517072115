import Vue from 'vue'
import Swatches from "@/components/Swatches";
import ImageGallery from "@/components/ImageGallery";
import ImageTile from "@/components/ImageTile";
import VirtualTryOn from "@/components/VirtualTryOn";
import './styles/app.scss'

Vue.config.productionTip = false

export const swatches = (el) =>
  new Vue({
    el,
    data: () => Object.assign({}, el.dataset) ,
    render: h => h(Swatches),
  });

export const vto = (el) =>
  new Vue({
    el,
    data: () => Object.assign({}, el.dataset) ,
    render: h => h(VirtualTryOn),
  });

export const gallery = (el) =>
  new Vue({
    el,
    data: () => Object.assign({}, el.dataset) ,
    render: h => h(ImageGallery),
  });

export const imageTile = (el) =>
  new Vue({
    el,
    data: () => Object.assign({}, el.dataset) ,
    render: h => h(ImageTile),
  });
