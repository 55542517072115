<template>
    <div>
        <a class="vto-button" @click="open">
            <span>
                Virtual Try On
            </span>
        </a>
        <OVtoPopup
                v-if="openPanel === 'vto'"
                :initial-shade-family="initialFamily"
                :initial-swatch="initialSwatch"
                @close="closeEverything"
        />
    </div>
</template>

<script>
  import OVtoPopup from "@/components/organisms/o-vto-popup";
  import productMap from '@/product-map.json'

  export default {
    name: "VirtualTryOn",
    components: {OVtoPopup},
    data() {
      return {
        openPanel: '',
        initialSwatch: '',
        initialFamily: ''
      }
    },
    mounted(){
      document.addEventListener('shopify/variant-select', (e) => {
        let swatch = this.allSwatches().find(x => x.sku === e.detail.variantSku)
        if(swatch) {
          this.initialFamily = swatch.shade_family;
          this.initialSwatch = swatch
        }
      });

      const queryString = window.location.search;
      const parameters = new URLSearchParams(queryString);
      const value = parameters.get('vto');
      if(value) {
        this.open();
      }
    },
    methods: {
      allSwatches () {
        return Object.keys(productMap).map(key => {
          return {
            colorCode: key,
            src: require(`@/assets/swatches/${key}.jpg`),
            ...productMap[key]
          }
        })
      },
      open() {
        this.openPanel = 'vto'
      },
      closeEverything: function() {
        this.openPanel = ''
      },
    }
  }
</script>

<style lang="scss" scoped>
    .vto-button {
        cursor: pointer;
        background-image: url("https://igkhair.s3.amazonaws.com/public/Holo_CTA.png");
        background-size: cover;
        border: 1px solid #000;
        display: block;
        padding: 0.5rem 1rem;
        text-align: center;
        span {
            font-weight: bold;
            text-transform: uppercase;
        }
    }
</style>
