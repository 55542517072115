<template>
    <img v-if="src" :src="src" :alt="alt"/>
</template>

<script>
  export default {
    name: "ImageTile",
    data() {
      return {
        id: null,
        src: '',
        alt: ''
      }
    },
    mounted() {
      document.addEventListener('shopify/image-tile' + (this.id ? '-' + this.id : ''), (e) => {
        this.src = e.detail.src || '';
        this.alt = e.detail.alt || ''
      })
    }
  }
</script>

<style scoped>

</style>
