<template>
    <div class="vto-tile">
        <div id="YMK-module"></div>
    </div>
</template>

<script>
  export default {
    name: "m-vto-tile",
    data() {
      return {
        width: 400,
        height: 600,
        listeners: []
      }
    },
    created() {
      (function (d, k) {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://plugins-media.makeupar.com/c41737/sdk.js?apiKey=' + k;
        var x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      })(document, 'RyqJ4bY9HEWFqZoMgPsXA');
    },
    destroyed() {
      if (window.YMK) {
        this.listeners.forEach((x) => window.YMK.removeEventListener(x))
      }
    },
    mounted () {
      if (this.isMobile) {
        let toolbarHeight = 50; // in px
        this.width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        this.width = (this.width > 400) ? 400 : this.width;
        this.height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        this.height = (this.height > 600 ? 600 : this.height) - toolbarHeight;
      }

      window.ymkAsyncInit = () => {
        window.YMKSettings = {
          autoOpen: true,
          width: this.width,
          height: this.height
        };

        window.YMK.init(window.YMKSettings);

        window.YMKRunner = window.YMKRunner || [];
        window.YMKRunner.forEach((x) => x())

        window.dataLayer = window.dataLayer || [];

        let gtag = window.gtag || function() {
          window.dataLayer.push(arguments);
        };

        const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

        ['opened', 'closed', 'loaded', 'photoSaved'].forEach((x) => {
          this.listeners.push(window.YMK.addEventListener(x, () => {
            x = camelToSnakeCase(x)
            gtag('event', 'vto_' + x, {
              'event_category': 'vto',
              'event_label': 'vto_' + x
            });
          }))
        });


        ['skinAnalysisUpdated', 'skinAnalysisError'].forEach((x) => {
          this.listeners.push(window.YMK.addEventListener(x, (data) => {
            x = camelToSnakeCase(x)
            gtag('event', 'vto_' + x, {
              'event_category': 'vto',
              'event_label': 'vto_' + x,
              'value': data
            });
          }))
        })
      }
    },
    computed: {
      isMobile: () => Math.max(document.documentElement.clientWidth, window.innerWidth || 0) <= 1024,
    }
  }
</script>

<style scoped>
 .vto-tile {
     display: inline-block;
     margin: 0 auto;
 }
</style>
